import {QueryKey, useMutation, useQueries, useQuery, useSuspenseQuery} from '@tanstack/react-query';
import {pulseUserAtom} from 'atoms/auth';
import {loggedInEmployeeAtom} from 'atoms/employee';
import {
  Goal,
  GoalCreate,
  GoalData,
  GoalService,
  GoalsNumOfPrivateGoalsData,
  GoalUpdate,
  KeyResult,
  KeyResultCreate,
  KeyResultService,
  KeyResultUpdate,
  Team,
  UpdateCreate,
  UpdateService,
} from 'client';
import {sortEmployees} from 'helpers/utils';
import {useEmployees} from 'hooks/useEmployee';
import {useAtomValue} from 'jotai';
import {getService} from 'utilities';
import {TeamInfo} from 'views/goals_v2/types';
import {queryClient} from 'views/QueryClientWrapper';

const goalService = getService(GoalService);

export const useGoal = ({goalId}: {goalId: Goal['id']; withError?: boolean}) => {
  const {data} = useSuspenseQuery({
    queryKey: ['goal', goalId],
    queryFn: () => goalService.readGoalByIdApiV1GoalsGoalIdGet(goalId),
  });

  const res = data as GoalData;
  res.key_results.sort((a, b) => {
    if (a.sort === 0 && b.sort === 0) {
      if (!a?.created_at || !b.created_at) {
        return 0;
      }
      return a.created_at.localeCompare(b.created_at);
    } else {
      return a.sort - b.sort;
    }
  });
  return res;
};

export const useMyGoals = ({timeframe}: {timeframe: string}) => {
  const pulseUser = useAtomValue(pulseUserAtom);
  const {data} = useQuery({
    queryKey: ['goals', pulseUser?.email.split('@')[0], timeframe],
    queryFn: () => goalService.readGoalsApiV1GoalsGet(0, 100, timeframe),
  });

  data?.goals?.forEach((goal) => queryClient.setQueryData<Goal>(['goal', goal.id], goal));

  return data?.goals || [];
};

export const useMyGoalsSuspense = ({timeframe}: {timeframe: string}) => {
  const pulseUser = useAtomValue(pulseUserAtom);
  const {data} = useSuspenseQuery({
    queryKey: ['goals', pulseUser?.email.split('@')[0], timeframe],
    queryFn: () => goalService.readGoalsApiV1GoalsGet(0, 100, timeframe),
  });

  data?.goals?.forEach((goal) => queryClient.setQueryData<Goal>(['goal', goal.id], goal));

  return data?.goals || [];
};

export const useGoals = ({
  ldaps,
  timeframe,
  throwOnError,
}: {
  ldaps: string[];
  timeframe?: string;
  throwOnError?: boolean;
}) => {
  let numOfPrivateGoals = 0;
  const hasAllPrivateGoalsList: boolean[] = [];
  const queries = useQueries({
    queries: ldaps.map((ldap) => ({
      queryKey: ['goals', ldap, timeframe],
      queryFn: () => goalService.readGoalsByLdapIdApiV1GoalsUsersLdapGet(ldap, 0, 100, timeframe),
      throwOnError: throwOnError ?? true,
      suspense: true,
    })),
  });
  const isFetched = queries.every((query) => query.isFetched);
  const goals: GoalData[] = queries
    .map(({data}) => {
      // Count the number of private goals
      numOfPrivateGoals += data?.num_private_goals || 0;

      // Check if all goals are private
      hasAllPrivateGoalsList.push(data?.has_all_private_goals || false);

      // Populate goal query keys to save an extra request
      data?.goals?.map((goal) => {
        queryClient.setQueryData(['goal', goal.id], goal);
      });

      return data?.goals || [];
    })
    .flat()
    .filter(Boolean);

  return {
    isFetched,
    goals,
    numOfPrivateGoals,
    hasAllPrivateGoals: hasAllPrivateGoalsList.every(Boolean),
  };
};

export const useTeamsGoalsSuspense = ({
  teams,
  timeframe,
  limit = 100,
  includeSubteamMembers = false,
}: {
  teams: Team[];
  timeframe: string;
  limit?: number;
  includeSubteamMembers?: boolean;
}) => {
  const goalsData = useQueries({
    queries: teams.map((team) => ({
      queryKey: ['goals', team.slug, limit, timeframe, includeSubteamMembers],
      queryFn: () =>
        goalService.readGoalsByTeamSlugApiV1GoalsTeamsTeamSlugGet(
          team.slug || '',
          0,
          limit,
          timeframe,
          includeSubteamMembers
        ),
      throwOnError: false,
      suspense: true,
      enabled: !!team.slug,
    })),
  });
  const isFetched = goalsData.every((query) => query.isFetched);
  const goalsMap = goalsData.reduce(
    (acc, {data}, index) => {
      const teamSlug = teams[index].slug;

      // Populate goal query keys to save an extra request
      data?.forEach((goal) => {
        queryClient.setQueryData(['goal', goal.id], goal);
      });

      return {
        ...acc,
        [teamSlug || '']: data || [],
      };
    },
    {} as Record<string, GoalData[]>
  );

  return {isFetched, goalsMap};
};

export const useTeamGoals = ({
  team,
  timeframe,
  limit = 100,
  includeSubteamMembers = false,
  enabled = false,
}: {
  team: Team;
  timeframe: string;
  limit?: number;
  includeSubteamMembers?: boolean;
  enabled?: boolean;
}) => {
  const {
    isFetched,
    isLoading,
    data: goals,
  } = useQuery({
    queryKey: ['goals', team.slug, limit, timeframe, includeSubteamMembers],
    queryFn: () =>
      goalService.readGoalsByTeamSlugApiV1GoalsTeamsTeamSlugGet(
        team.slug || '',
        0,
        limit,
        timeframe,
        includeSubteamMembers
      ),
    throwOnError: false,
    enabled: !!team.slug && enabled,
  });
  return {isFetched, isLoading, goals};
};

export const useCompanyGoals = ({timeframe}: {timeframe: string}) => {
  const {data, isError} = useSuspenseQuery({
    queryKey: ['goals', 'company', timeframe],
    queryFn: () => goalService.readGoalsCompanyApiV1GoalsCompanyGet(0, 200, timeframe),
  });

  if (isError) {
    throw new Error('Failed to fetch company goals');
  }

  const employees = useEmployees({
    ldaps: data?.map(({users}) => users?.[0]?.email?.split('@')[0] ?? '') ?? [],
  });

  data?.forEach((goal) => queryClient.setQueryData<GoalData>(['goal', goal.id], goal));

  const sortedEmployees = employees.sort(sortEmployees);

  return sortedEmployees
    .map((employee) => data?.filter(({users}) => users?.[0]?.email === employee.email))
    .flat() as GoalData[];
};

export const useUpdateService = ({
  timeframe,
  teamInfo,
  parentGoalUserLdap,
  ownerLdaps = [],
  onSettledCallback,
}: {
  timeframe?: string;
  teamInfo?: TeamInfo;
  parentGoalUserLdap?: string;
  ownerLdaps?: string[];
  onSettledCallback?: () => void;
}) => {
  const {employeeTeams} = useAtomValue(loggedInEmployeeAtom);

  const {mutateAsync: addUpdate} = useMutation({
    mutationFn: ({
      userId,
      isDelegated,
      employeeId,
      data,
    }: {
      userId: string;
      employeeId?: string;
      isDelegated: boolean;
      ldap: string;
      data: UpdateCreate;
    }) => {
      const body = {...data};
      if (data.key_result_id) {
        delete body.goal_id;
      }

      return getService(UpdateService).createUpdateApiV1UpdatesPost(
        userId,
        body,
        employeeId,
        isDelegated
      );
    },
    onSettled: (response, _error, {data, ldap}) => {
      if (!response) {
        return;
      }
      queryClient.invalidateQueries({queryKey: ['notifications']});

      queryClient.setQueryData(['goal', data.goal_id], (old: Goal | undefined) => {
        if (!old) {
          queryClient.invalidateQueries({queryKey: ['goal', data.goal_id]});
          return;
        }

        const updated = {...old};

        if (data.key_result_id) {
          updated.key_results = updated.key_results.map((keyResult) =>
            keyResult.id === data.key_result_id
              ? {...keyResult, updates: [...keyResult.updates, response]}
              : keyResult
          );

          if (response.goal_status) {
            updated.updates = [
              ...updated.updates,
              {
                id: -1,
                status: response.goal_status,
                comment: 'automatic_objective_status_change',
                created_at: response.created_at,
                updated_at: response.updated_at,
              },
            ];
          }
        } else {
          updated.updates = [...updated.updates, response];
        }

        return updated;
      });

      const updatedGoal = queryClient.getQueryData<Goal>(['goal', data.goal_id]);
      if (ldap) {
        setOrInvalidateEmployeeGoals('update', ['goals', ldap], updatedGoal, timeframe);
      }
      if (ownerLdaps.length) {
        ownerLdaps.forEach((ownerLdap: string) => {
          if (ownerLdap && ownerLdap !== ldap) {
            setOrInvalidateEmployeeGoals('update', ['goals', ownerLdap], updatedGoal, timeframe);
          }
        });
      }
      if (
        parentGoalUserLdap &&
        parentGoalUserLdap !== ldap &&
        !ownerLdaps.includes(parentGoalUserLdap)
      ) {
        const parentGoalQueryKey = timeframe
          ? ['goals', parentGoalUserLdap, timeframe]
          : ['goals', parentGoalUserLdap];
        queryClient.refetchQueries({queryKey: parentGoalQueryKey});
      }

      if (teamInfo) {
        queryClient.invalidateQueries({queryKey: ['goals', teamInfo.teamSlug, teamInfo.timeframe]});
      }
      // go over all the employee teams and invalidate the queries
      employeeTeams?.forEach((team) => {
        if (teamInfo && teamInfo.teamSlug == team.slug) {
          //skip the team that was already invalidated
          return;
        }
        queryClient.invalidateQueries({queryKey: ['goals', team.slug]});
      });

      onSettledCallback?.();
    },
  });

  return {addUpdate};
};

export const useGoalService = ({
  timeframe,
  teamInfo,
  parentGoalUserLdap,
}: {
  timeframe: string;
  teamInfo?: TeamInfo;
  parentGoalUserLdap?: string;
}) => {
  const onSettled = (response: Goal | undefined, _error: any, _variables: any, context: any) => {
    if (!response) {
      return;
    }
    queryClient.invalidateQueries({queryKey: ['notifications']});
    queryClient.setQueryData(['goal', response.id], response);

    const updatedGoal = queryClient.getQueryData<Goal>(['goal', response.id]);
    const ldap = context?.ldap ?? response.users?.[0]?.email?.split('@')[0];
    if (ldap) {
      setOrInvalidateEmployeeGoals(context.type, ['goals', ldap], updatedGoal, timeframe);
    }
    const reponseParentGoalUserLdap = response.parent?.users?.[0]?.email?.split('@')[0];
    const parentGoalUserLdapToRefetch = reponseParentGoalUserLdap ?? parentGoalUserLdap;
    if (response.parent) {
      queryClient.invalidateQueries({queryKey: ['goal', response.parent.id]});
    }
    if (parentGoalUserLdapToRefetch && parentGoalUserLdapToRefetch !== ldap) {
      const parentGoalQueryKey = timeframe
        ? ['goals', parentGoalUserLdapToRefetch, timeframe]
        : ['goals', parentGoalUserLdapToRefetch];
      queryClient.refetchQueries({queryKey: parentGoalQueryKey});
    }

    if (teamInfo) {
      queryClient.invalidateQueries({queryKey: ['goals', teamInfo.teamSlug, teamInfo.timeframe]});
    }
  };

  const {mutateAsync: createGoal} = useMutation({
    mutationFn: ({data, employeeId}: {data: GoalCreate; employeeId?: string}) =>
      getService(GoalService).createGoalApiV1GoalsPost(data, employeeId),
    onMutate: () => ({type: 'create'}),
    onSettled,
  });

  const {mutateAsync: updateGoal} = useMutation({
    mutationFn: ({goalId, userId, data}: {goalId: number; userId: string; data: GoalUpdate}) =>
      getService(GoalService).updateGoalApiV1GoalsGoalIdPut(goalId, userId, data as GoalUpdate),
    onMutate: () => ({type: 'update'}),
    onSettled,
  });

  const {mutateAsync: deleteGoal} = useMutation({
    mutationFn: ({goalId, userId}: {goalId: number; userId: string}) =>
      getService(GoalService).deleteGoalApiV1GoalsGoalIdDelete(goalId, userId),
    onMutate: (data) => {
      const goal: Goal | undefined = queryClient.getQueryData(['goal', data.goalId]);
      return {type: 'delete', ldap: goal?.users?.[0]?.email?.split('@')[0]};
    },
    onSettled,
  });

  const {mutateAsync: continueGoal} = useMutation({
    mutationFn: ({
      goalId,
      userId,
      nextTimeframe,
      employeeId,
    }: {
      goalId: number;
      userId: string;
      nextTimeframe: string;
      employeeId?: string;
    }) =>
      getService(GoalService).continueGoalApiV1GoalsGoalIdContinuePost(
        goalId,
        userId,
        nextTimeframe,
        new Date().toISOString(),
        employeeId
      ),
    onMutate: () => ({type: 'continue'}),
    onSettled,
  });

  return {createGoal, updateGoal, deleteGoal, continueGoal};
};

const setOrInvalidateEmployeeGoals = (
  actionType: string,
  key: QueryKey,
  updatedGoal: GoalData | undefined,
  timeframe?: string
) => {
  const queryKey = timeframe && key.includes(timeframe) ? key : [...key, timeframe];
  const prevNumOfPrivateGoalsData = queryClient.getQueryData<GoalsNumOfPrivateGoalsData>(queryKey);
  const hasParentGoal = !!(updatedGoal && (updatedGoal.parent || updatedGoal.key_result_parent_id));
  if (
    !prevNumOfPrivateGoalsData ||
    ['continue', 'continueKeyResult'].includes(actionType) ||
    hasParentGoal
  ) {
    return queryClient.invalidateQueries({queryKey});
  }
  const prevGoals = prevNumOfPrivateGoalsData.goals;
  if (prevGoals && updatedGoal) {
    let goals = [...prevGoals];
    if (actionType === 'create') {
      const prevGoal = goals.find((goal) => goal.id === updatedGoal.id);
      if (prevGoal) {
        goals = goals.map((goal) => (goal.id === updatedGoal.id ? updatedGoal : goal));
      } else {
        goals = [...goals, updatedGoal];
      }
    } else if (actionType === 'update') {
      goals = goals.map((goal) => (goal.id === updatedGoal.id ? updatedGoal : goal));
    } else if (actionType === 'delete') {
      goals = goals
        .filter((goal) => goal.id !== updatedGoal.id)
        .map((goal) => {
          // Remove the deleted goal from the children of the parent goal
          return {...goal, children: goal.children?.filter((child) => child.id !== updatedGoal.id)};
        });
    } else if (actionType === 'deleteKeyResult') {
      const prevGoalIndex = goals.findIndex((goal) => goal.id === updatedGoal.id);
      goals[prevGoalIndex] = updatedGoal;
    }

    const newNumOfPrivateGoalsData = {...prevNumOfPrivateGoalsData, goals};
    queryClient.setQueryData(queryKey, newNumOfPrivateGoalsData);
  }
};

const getUpdatedGoal = (
  oldGoal: Goal,
  response: KeyResult,
  type: 'create' | 'update' | 'deleteKeyResult'
) => {
  let updatedKeyResults: KeyResult[] = oldGoal.key_results; // Default to the existing key results

  if (type === 'create') {
    updatedKeyResults = [...oldGoal.key_results, response];
  } else if (type === 'update') {
    updatedKeyResults = oldGoal.key_results.map((kr) =>
      kr.id === response.id ? {...response} : kr
    );
  } else if (type === 'deleteKeyResult') {
    updatedKeyResults = oldGoal.key_results.filter((kr) => kr.id !== response.id);
  }

  return {...oldGoal, key_results: updatedKeyResults};
};

export const useKeyResultService = ({
  timeframe,
  teamInfo,
  ownerLdaps,
  teamsSlug,
  forceUpdate = false,
}: {
  timeframe?: string;
  teamInfo?: TeamInfo;
  ownerLdaps?: string[];
  teamsSlug?: string[];
  forceUpdate?: boolean;
}) => {
  const pulseUser = useAtomValue(pulseUserAtom);
  const {employeeTeams} = useAtomValue(loggedInEmployeeAtom);

  const onSettled = (
    response: KeyResult | undefined,
    _error: any,
    _variables: any,
    context: any
  ) => {
    if (!response) {
      return;
    }

    const goalId = response.goal_id;
    const goal: Goal | undefined = queryClient.getQueryData(['goal', goalId]);

    if (!goal) {
      queryClient.invalidateQueries({queryKey: ['goal', goalId]});
    } else {
      // Set the updated goal
      queryClient.setQueryData(['goal', goalId], getUpdatedGoal(goal, response, context.type));
    }

    const ldap = goal?.users?.[0]?.email?.split('@')[0];
    const updatedGoal = queryClient.getQueryData<Goal>(['goal', goalId]);
    if (ldap) {
      setOrInvalidateEmployeeGoals(context.type, ['goals', ldap], updatedGoal, timeframe);
    }
    if (ownerLdaps) {
      ownerLdaps.forEach((currentLdap: string) => {
        if (forceUpdate && currentLdap) {
          queryClient.invalidateQueries({queryKey: ['goals', currentLdap], type: 'all'});
        } else {
          if (currentLdap !== ldap) {
            setOrInvalidateEmployeeGoals(
              context.type,
              ['goals', currentLdap],
              updatedGoal,
              timeframe
            );
          }
        }
      });
    }
    if (pulseUser?.email.split('@')[0] !== ldap) {
      setOrInvalidateEmployeeGoals(
        context.type,
        ['goals', pulseUser?.email.split('@')[0]],
        updatedGoal,
        timeframe
      );
    }

    if (teamsSlug) {
      teamsSlug.forEach((teamSlug: string) => {
        queryClient.invalidateQueries({queryKey: ['goals', teamSlug]});
      });
    }
    if (teamInfo) {
      const {teamSlug} = teamInfo;
      const includedInTeamsSlug = teamsSlug?.includes(teamSlug);
      if (!includedInTeamsSlug) {
        queryClient.invalidateQueries({queryKey: ['goals', teamInfo.teamSlug]});
      }
    }
    employeeTeams?.forEach((team) => {
      queryClient.invalidateQueries({queryKey: ['goals', team.slug]});
    });
  };

  const {mutateAsync: createKeyResult} = useMutation({
    mutationFn: ({goalId, userId, data}: {goalId: number; userId: string; data: KeyResultCreate}) =>
      getService(KeyResultService).createKeyResultApiV1GoalsGoalIdKeyResultsPost(
        goalId,
        userId,
        data
      ),
    onMutate: () => ({type: 'create'}),
    onSettled,
  });

  const {mutateAsync: updateKeyResult} = useMutation({
    mutationFn: ({
      keyResultId,
      userId,
      data,
    }: {
      keyResultId: number;
      userId: string;
      data: KeyResultUpdate;
    }) =>
      getService(KeyResultService).updateKeyResultApiV1KeyResultsKeyResultIdPut(
        keyResultId,
        userId,
        data
      ),
    onMutate: () => ({type: 'update'}),
    onSettled,
  });

  const {mutateAsync: updateKRContributors} = useMutation({
    mutationFn: ({
      keyResultId,
      ownerId,
      employeeId,
      join,
    }: {
      keyResultId: number;
      ownerId: string;
      employeeId: string;
      join: boolean;
    }) =>
      getService(KeyResultService).contributeApiV1KeyResultsKeyResultIdContributePost(
        keyResultId,
        ownerId,
        employeeId,
        join
      ),
    onMutate: () => ({type: 'update'}),
    onSettled,
  });

  const {mutateAsync: deleteKeyResult} = useMutation({
    mutationFn: ({keyResultId, userId}: {keyResultId: number; userId: string}) =>
      getService(KeyResultService).deleteKeyResultApiV1KeyResultsKeyResultIdDelete(
        keyResultId,
        userId,
        false
      ),
    onMutate: () => ({type: 'deleteKeyResult'}),
    onSettled,
  });

  const {mutateAsync: deleteDryRunKeyResult} = useMutation({
    mutationFn: ({keyResultId, userId}: {keyResultId: number; userId: string}) =>
      getService(KeyResultService).deleteKeyResultApiV1KeyResultsKeyResultIdDelete(
        keyResultId,
        userId,
        true
      ),
    onMutate: () => ({type: 'deleteDryRun'}),
    onSettled,
  });

  const {mutateAsync: continueKeyResult} = useMutation({
    mutationFn: ({
      keyResultId,
      userId,
      nextTimeframe,
      employeeId,
    }: {
      keyResultId: number;
      userId: string;
      nextTimeframe: string;
      employeeId?: string;
    }) =>
      getService(KeyResultService).continueKeyResultApiV1KeyResultsKeyResultIdContinuePost(
        keyResultId,
        userId,
        nextTimeframe,
        new Date().toISOString(),
        employeeId
      ),
    onMutate: () => ({type: 'continueKeyResult'}),
    onSettled,
  });

  return {
    createKeyResult,
    updateKeyResult,
    updateKRContributors,
    deleteKeyResult,
    deleteDryRunKeyResult,
    continueKeyResult,
  };
};

export const useGoalUpdateData = (goal: Goal): GoalUpdate => {
  const goalUpdate: GoalUpdate = {
    title: goal.title,
    timeframe: goal.timeframe,
    private: goal.private,
    strategy_ids: goal.strategy_ids ?? '',
    project_ids: goal.projects?.map(({id}) => id),
    key_result_parent_id: goal.key_result_parent_id ?? undefined,
    parent_id: goal?.parent?.id ?? undefined,
    is_custom_privacy_included: goal.is_custom_privacy_included,
    individual_privacies: goal.individual_privacies,
    team_privacies: goal.team_privacies,
    created_at: goal.created_at,
  };
  return goalUpdate;
};

export const useKeyResult = ({keyResultId}: {keyResultId: KeyResult['id']}) => {
  const {data} = useSuspenseQuery({
    queryKey: ['key_result', keyResultId],
    queryFn: () =>
      getService(KeyResultService).readKeyResultApiV1KeyResultsKeyResultIdGet(keyResultId),
  });
  return data;
};

import {growthbookCacheAtom} from 'atoms/layout';
import {Team} from 'client';
import {useTeamGoals, useTeamsGoalsSuspense} from 'components/goals/hooks';
import {useTotalGoalCountsQuerySuspense} from 'hooks/useDashboardsGoalCounts';
import {useAtomValue} from 'jotai';
import {useEffect, useState} from 'react';
import {DASHBOARDS_DROPBOX_TEAM_SLUG} from 'views/dashboards/constants';
import {
  DASHBOARDS_MAX_NUM_GOALS_T0_FETCH,
  DASHBOARDS_MAX_NUM_GOALS_T0_SHOW,
} from 'views/dashboards/constants';
import {NoFilterTable} from 'views/goals_v2/EmptyGoalsTable';
import {GoalsTable} from 'views/goals_v2/GoalsTable';
import {GoalsHeaderData, GoalsTablePropsWithoutData} from 'views/goals_v2/types';
import {
  filterTable,
  getEmptyFilter,
  getExpandedTableProps,
  hasTooManyGoals,
  MAX_NUM_GOALS_TO_SHOW,
} from 'views/goals_v2/utils';
import {queryClient} from 'views/QueryClientWrapper';

/*
 * Table that loads employee goals for team members (based on `team`)
 */
export const TeamGoalsTableComponent = ({
  index,
  keyPrefix,
  team,
  filter = getEmptyFilter(),
  timeframe,
  nextTimeframe,
  setNextTimeframe,
  columnConfigs,
  onColumnDrag,
  isGoalsV2DrawerOpen,
  onRowClick,
  onContributorsClick,
  onContinueGoalClick,
  hasScrolledToRow,
  setScrolledToRow,
  selectedGoalData,
  goalsHeaderData,
  setGoalsHeaderData,
  handlerAddFilterButtonClick,
}: GoalsTablePropsWithoutData & {
  index: number;
  keyPrefix: string;
  team: Team;
}) => {
  const {isDashboardsEnabled} = useAtomValue(growthbookCacheAtom);

  const [tableMaxGoalsLimit, setTableMaxGoalsLimit] = useState<number>(
    DASHBOARDS_MAX_NUM_GOALS_T0_SHOW
  );

  const teamSlug = team.slug ?? '';
  const teamsSet = new Set([team, ...filter.teams]);
  const teams = Array.from(teamsSet);
  const includeSubteamMembers = isDashboardsEnabled && teamSlug !== DASHBOARDS_DROPBOX_TEAM_SLUG;
  const {isFetched, goalsMap: filterTeamGoalsMap} = useTeamsGoalsSuspense({
    teams,
    timeframe: timeframe,
    limit: isDashboardsEnabled ? tableMaxGoalsLimit : undefined,
    includeSubteamMembers,
  });
  const teamGoals = filterTeamGoalsMap[teamSlug] ?? [];
  const filteredTeamGoals = teamGoals.filter(filterTable(filter));

  // Used to prefetch next batch of goals for show more
  const totalGoalCountsData = useTotalGoalCountsQuerySuspense({
    teamSlug: teamSlug,
    timeframe,
  });
  const totalGoalsCount = Object.values(totalGoalCountsData).reduce((acc, count) => acc + count, 0);
  const shouldShowMore = isDashboardsEnabled && totalGoalsCount >= MAX_NUM_GOALS_TO_SHOW;
  const {
    isFetched: isShowMoreFetched,
    isLoading: isShowMoreLoading,
    goals: teamGoalsForShowMore = [],
  } = useTeamGoals({
    team: teams[0],
    timeframe: timeframe,
    limit: DASHBOARDS_MAX_NUM_GOALS_T0_FETCH,
    includeSubteamMembers,
    enabled: !!teams.length && shouldShowMore,
  });
  const filteredTeamGoalsForShowMore = teamGoalsForShowMore.filter(filterTable(filter));

  useEffect(() => {
    if (isDashboardsEnabled && shouldShowMore && isShowMoreFetched) {
      // Populate current goals cache with rest of goals
      queryClient.setQueryData(
        ['goals', team.slug, tableMaxGoalsLimit, timeframe, includeSubteamMembers],
        filteredTeamGoalsForShowMore
      );
      // Update max goals limit to display next bach of goals
      setTableMaxGoalsLimit(DASHBOARDS_MAX_NUM_GOALS_T0_FETCH);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShowMoreFetched]);

  const goalsHeaderDataString = JSON.stringify(goalsHeaderData);
  const filterString = JSON.stringify(filter);
  useEffect(() => {
    // After goals are fetched for team, store goals count for header
    if (isFetched && setGoalsHeaderData && teamSlug) {
      const goalsCountMap = goalsHeaderData?.goalsCountMap ?? {};
      const filteredGoalsCountMap = goalsHeaderData?.filteredGoalsCountMap ?? {};
      const teamsMap = goalsHeaderData?.teamsMap ?? {};
      goalsCountMap[teamSlug] = teamGoals.length;
      filteredGoalsCountMap[teamSlug] =
        shouldShowMore && filteredTeamGoals.length === teamGoals.length
          ? 0
          : filteredTeamGoals.length;
      teamsMap[teamSlug] = team;
      const newGoalsHeaderData: GoalsHeaderData = {
        ...goalsHeaderData,
        goalsCountMap,
        filteredGoalsCountMap,
        teamsMap,
        showManyGoalsCount: shouldShowMore,
      };
      setGoalsHeaderData(newGoalsHeaderData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isFetched,
    goalsHeaderDataString,
    setGoalsHeaderData,
    filterString,
    timeframe,
    tableMaxGoalsLimit,
  ]);

  if (goalsHeaderData && handlerAddFilterButtonClick && hasTooManyGoals(goalsHeaderData, filter)) {
    return index === 0 ? <NoFilterTable onButtonClick={handlerAddFilterButtonClick} /> : null;
  }

  return (
    <GoalsTable
      keyPrefix={keyPrefix}
      withTitle={team.name ?? ''}
      type="other"
      data={filteredTeamGoals}
      timeframe={timeframe}
      nextTimeframe={nextTimeframe}
      setNextTimeframe={setNextTimeframe}
      filter={filter}
      columnConfigs={columnConfigs}
      onColumnDrag={onColumnDrag}
      isGoalsV2DrawerOpen={isGoalsV2DrawerOpen}
      onRowClick={onRowClick}
      onContributorsClick={onContributorsClick}
      onContinueGoalClick={onContinueGoalClick}
      hasScrolledToRow={hasScrolledToRow}
      setScrolledToRow={setScrolledToRow}
      selectedGoalData={selectedGoalData}
      teamInfo={{teamSlug, timeframe}}
      shouldShowMore={shouldShowMore}
      isShowMoreLoading={isShowMoreLoading}
      {...getExpandedTableProps(true)}
    />
  );
};

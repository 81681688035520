export const DASHBOARDS_DEFAULT_STATUS = 'on_track';

export const DASHBOARDS_STATUS_THRESHOLDS: {[key: string]: number} = {
  no_status: 50,
  on_track: 50,
  at_risk: 50,
  off_track: 50,
  complete: 50,
  missed: 50,
  cancelled: 50,
};

export const DASHBOARDS_CELL_STATUS_COLORS: {[key: string]: string} = {
  no_status: '#DFDCD8',
  on_track: '#DFF29F',
  at_risk: '#FBE8AE',
  off_track: '#FB9D83',
  complete: '#B5D834',
  missed: '#F77751',
  cancelled: '#DFDCD8',
};

export const DASHBOARDS_SEGMENT_STATUS_COLORS: {[key: string]: string} = {
  no_status: '#FFFFFF',
  on_track: '#DFF29F',
  at_risk: '#FBE8AE',
  off_track: '#FB9D83',
  complete: '#B5D834',
  missed: '#F77751',
  cancelled: '#DFDCD8',
};

export const DASHBOARD_STATUSES = Object.keys(DASHBOARDS_SEGMENT_STATUS_COLORS);

export const DASHBOARDS_DROPBOX_NAME = 'Dropbox';
export const DASHBOARDS_DROPBOX_TEAM_SLUG = 'dropbox-teams';

export const DASHBOARDS_MAX_NUM_GOALS_T0_SHOW = 25;
export const DASHBOARDS_MAX_NUM_GOALS_T0_FETCH = 350;

import {useQueries, useQuery, useSuspenseQuery} from '@tanstack/react-query';
import {DashboardService} from 'client';
import {getService} from 'utilities';
import {DASHBOARDS_DROPBOX_TEAM_SLUG} from 'views/dashboards/constants';

export type DashboardsGoalCounts = {
  team_slug: string;
  total_counts: {[status: string]: number};
  members_counts: {[ldap: string]: {[status: string]: number}};
  subteams_counts?: {[slug: string]: {[status: string]: number}};
  is_total_counts_loading?: boolean;
};

const useTotalGoalCountsQuery = ({teamSlug, timeframe}: {teamSlug: string; timeframe: string}) => {
  return useQuery({
    queryKey: ['dashboardsTotalGoalCounts', teamSlug, timeframe],
    queryFn: () =>
      getService(DashboardService).totalGoalCountsApiV1DashboardsTotalGoalCountsGet(
        teamSlug,
        timeframe
      ),
  });
};

export const useTotalGoalCountsQuerySuspense = ({
  teamSlug,
  timeframe,
}: {
  teamSlug: string;
  timeframe: string;
}) => {
  const {data} = useSuspenseQuery({
    queryKey: ['dashboardsTotalGoalCounts', teamSlug, timeframe],
    queryFn: () =>
      getService(DashboardService).totalGoalCountsApiV1DashboardsTotalGoalCountsGet(
        teamSlug,
        timeframe
      ),
  });
  return (data?.total_counts ?? {}) as {[status: string]: number};
};

const useMemberGoalCountsQuery = ({
  teamSlug,
  timeframe,
  enabled = false,
}: {
  teamSlug: string;
  timeframe: string;
  enabled?: boolean;
}) => {
  return useQuery({
    queryKey: ['dashboardsMemberGoalCounts', teamSlug, timeframe],
    queryFn: () =>
      getService(DashboardService).memberGoalCountsApiV1DashboardsMemberGoalCountsGet(
        teamSlug,
        timeframe
      ),
    enabled,
  });
};

export const useDashboardsGoalCounts = ({
  teamSlug,
  subteamSlugs,
  timeframe,
  includeMembersCounts,
}: {
  teamSlug: string;
  subteamSlugs: string[];
  timeframe: string;
  includeMembersCounts: boolean;
}): DashboardsGoalCounts => {
  const {data: totalGoalCountsData, isLoading: isTotalCountsLoading} = useTotalGoalCountsQuery({
    teamSlug,
    timeframe,
  });

  const queries = useQueries({
    queries: subteamSlugs.map((subteamSlug) => ({
      queryKey: ['dashboardsTotalGoalCounts', subteamSlug, timeframe],
      queryFn: () =>
        getService(DashboardService).totalGoalCountsApiV1DashboardsTotalGoalCountsGet(
          subteamSlug,
          timeframe
        ),
      throwOnError: false,
      suspense: false,
    })),
  });

  const subteams_counts: {[slug: string]: {[status: string]: number}} = {};
  queries.forEach(({data}) => {
    if (!data) {
      return;
    }
    const queryData = data as DashboardsGoalCounts;
    subteams_counts[queryData.team_slug] = queryData.total_counts;
  });

  const {data: memberGoalCountsData} = useMemberGoalCountsQuery({
    teamSlug,
    timeframe,
    enabled: includeMembersCounts,
  });

  return {
    team_slug: DASHBOARDS_DROPBOX_TEAM_SLUG,
    total_counts: totalGoalCountsData?.total_counts,
    members_counts: includeMembersCounts ? memberGoalCountsData?.members_counts : {},
    subteams_counts,
    is_total_counts_loading: isTotalCountsLoading,
  };
};

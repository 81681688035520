import {LabelGroup} from '@dropbox/dig-components/dist/combinations';
import {Spinner} from '@dropbox/dig-components/dist/progress_indicators';
import {Overlay, OverlayRefObject} from '@dropbox/dig-components/overlay';
import {Text} from '@dropbox/dig-components/typography';
import {Box} from '@dropbox/dig-foundations';
import {StatusButtonIcon} from 'components/DSYS/StatusButtonIcon';
import {formatTimeframe} from 'components/shared/TimeAgo';
import {t} from 'i18next';
import {useRef, useState} from 'react';
import {DASHBOARD_STATUSES, DASHBOARDS_SEGMENT_STATUS_COLORS} from 'views/dashboards/constants';
import {
  getGraphSegments,
  getStatusColorOverride,
  getStatusGoalCountsData,
} from 'views/dashboards/util';

import styles from './Dashboards.module.css';

interface DashboardsGoalsOverviewGraphProps {
  timeframe: string;
  height: number;
  width: string;
  goalCounts?: {[status: string]: number};
  onClick?: (status: string) => void;
  showTooltip?: boolean;
  showInfoOnHover?: boolean;
  isLoading?: boolean;
}

export const DashboardsGoalsOverviewGraph = (props: DashboardsGoalsOverviewGraphProps) => {
  const {height, width, goalCounts, isLoading} = props;
  const {firstSegmentStatus, lastSegmentStatus} = getGraphSegments(goalCounts);

  return (
    <Box
      className={styles.dashboardsGoalsOverviewGraph}
      backgroundColor="Background Subtle"
      borderRadius="Medium"
      borderColor="Border Subtle"
      borderStyle="Solid"
      borderWidth="1"
      style={{height, width}}
    >
      {isLoading ? (
        <Box display="flex" justifyContent="center" width="100%">
          <Spinner size="xsmall" monochromatic />
        </Box>
      ) : (
        DASHBOARD_STATUSES.map((status) => {
          return (
            <DashboardsGoalsOverviewSegment
              key={`dashboards-goals-overview-status-segment__${status}`}
              status={status}
              isFirstSegment={status === firstSegmentStatus}
              isLastSegment={status === lastSegmentStatus}
              {...props}
            />
          );
        })
      )}
    </Box>
  );
};

// Copied from DIG Overlays documentation
class VirtualAnchor {
  position: [number, number];

  constructor() {
    this.position = [0, 0];
  }

  getBoundingClientRect() {
    return {
      height: 0,
      width: 0,
      top: this.position[1],
      right: this.position[0],
      bottom: this.position[1],
      left: this.position[0],
      x: 0,
      y: 0,
      toJSON: () => undefined,
    };
  }
}

export const DashboardsGoalsOverviewSegment = ({
  timeframe,
  status,
  goalCounts,
  onClick,
  height,
  isFirstSegment = false,
  isLastSegment = false,
  showTooltip = false,
  showInfoOnHover = false,
}: DashboardsGoalsOverviewGraphProps & {
  status: string;
  isFirstSegment?: boolean;
  isLastSegment?: boolean;
}) => {
  const goalCountsData = getStatusGoalCountsData(status, goalCounts);
  const perc = goalCountsData?.percentageCeil ?? 0;

  const [isHovered, setHovered] = useState(false);

  const cssClasses = [styles.dashboardsGoalsOverviewSegment];
  if (isFirstSegment) {
    cssClasses.push(styles.dashboardsGoalsOverviewSegmentRoundedLeft);
  }
  if (isLastSegment) {
    cssClasses.push(styles.dashboardsGoalsOverviewSegmentRoundedRight);
  }

  const anchorRef = useRef(new VirtualAnchor());
  const overlayRef = useRef<OverlayRefObject>(null);

  const handleMouseMove: React.MouseEventHandler<HTMLElement> = (evt) => {
    anchorRef.current.position = [evt.clientX, evt.clientY];
    if (overlayRef.current) overlayRef.current.update();
  };

  const handleMouseEnter: React.MouseEventHandler<HTMLElement> = () => {
    setHovered(true);
  };

  const handleMouseLeave: React.MouseEventHandler<HTMLElement> = () => {
    setHovered(false);
  };

  return (
    <>
      {isHovered && showInfoOnHover && (
        <Box position="absolute" style={{top: '-26px'}}>
          <Text size="xsmall" color="subtle">{`${perc}% ${t(status)}`}</Text>
        </Box>
      )}
      {!!perc && (
        <>
          <Box
            className={cssClasses.join(' ')}
            style={{
              backgroundColor: goalCountsData?.segmentStatusColor,
              height: height - 2, // Subtract 2 for border
              width: `${perc}%`,
            }}
            onClick={() => onClick?.(status)}
            onMouseMove={handleMouseMove}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          />
          {showTooltip && isHovered && (
            <Overlay
              auto
              ref={overlayRef}
              anchorRef={anchorRef}
              placement="right"
              offsetDistance={15}
              className={styles.dashboardsGoalsOverviewSegmentTooltip}
            >
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                style={{width: '130px'}}
              >
                <Box
                  as="div"
                  alignItems="flex-start"
                  display="flex"
                  style={{gap: '4px', marginBottom: '18px'}}
                >
                  <StatusButtonIcon
                    status={status}
                    statusColorOverride={getStatusColorOverride(
                      status,
                      DASHBOARDS_SEGMENT_STATUS_COLORS
                    )}
                  />
                  <LabelGroup
                    size="small"
                    withText={
                      <Text size="small" isBold>
                        {t(status)}
                      </Text>
                    }
                    withSubtext={formatTimeframe(timeframe)}
                  />
                </Box>
                <Box style={{alignSelf: 'flex-end'}}>
                  <LabelGroup
                    size="small"
                    withText={
                      <Text style={{fontSize: '23px'}} isBold>
                        {goalCounts?.[status] ?? 0}
                      </Text>
                    }
                    withSubtext={
                      goalCountsData
                        ? t('goals_overview_out_of', {
                            count: goalCountsData.totalCount ?? 0,
                          })
                        : undefined
                    }
                  />
                </Box>
              </Box>
            </Overlay>
          )}
        </>
      )}
    </>
  );
};

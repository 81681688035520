import {DEFAULT_TIMEFRAME, ROUTE_PATHS} from 'constant';
import {To} from 'react-router';
import {
  DASHBOARD_STATUSES,
  DASHBOARDS_CELL_STATUS_COLORS,
  DASHBOARDS_SEGMENT_STATUS_COLORS,
  DASHBOARDS_STATUS_THRESHOLDS,
} from 'views/dashboards/constants';

export interface GoalCountsData {
  status: string;
  statusCount: number;
  statusCounts: {[status: string]: number};
  totalCount: number;
  percentageCeil: number;
  segmentStatusColor: string;
  cellStatusColor?: string;
}

export const getStatusGoalCountsData = (
  status: string,
  statusCounts?: {[status: string]: number}
): GoalCountsData | undefined => {
  if (!statusCounts) {
    return undefined;
  }
  const statusCount = statusCounts[status] ?? 0;
  const totalCount = Object.values(statusCounts).reduce((acc, current) => acc + current, 0);
  const percentageCeil = Math.ceil((statusCount / totalCount) * 100) || 0;
  return {
    status,
    statusCount,
    statusCounts,
    totalCount,
    percentageCeil,
    cellStatusColor:
      percentageCeil >= DASHBOARDS_STATUS_THRESHOLDS[status]
        ? DASHBOARDS_CELL_STATUS_COLORS[status]
        : undefined,
    segmentStatusColor: DASHBOARDS_SEGMENT_STATUS_COLORS[status],
  };
};

export const getStatusColorOverride = (
  status: string,
  statusColors: {[key: string]: string}
): string | undefined => {
  return status === 'no_status' ? undefined : statusColors[status];
};

export const getGraphSegments = (goalCounts: {[status: string]: number} | undefined) => {
  if (!goalCounts) {
    return {};
  }
  const firstSegmentStatus = DASHBOARD_STATUSES.find((status) => goalCounts[status] > 0);
  const lastSegmentStatus = [...DASHBOARD_STATUSES]
    .reverse()
    .find((status) => goalCounts[status] > 0);
  return {firstSegmentStatus, lastSegmentStatus};
};

export const createTeamDashboardsPath = (teamSlug: string, timeframe?: string): To => {
  const urlParams = new URLSearchParams();
  if (timeframe) {
    urlParams.set('t', timeframe);
  }
  return {pathname: `${ROUTE_PATHS.DASHBOARDS}/${teamSlug}`, search: urlParams.toString()};
};

export const createTeamsPath = (teamSlug: string): To => {
  return `/teams/${teamSlug}`;
};

export const createTeamGoalsPath = (
  teamSlug: string,
  status: string | undefined,
  timeframe: string
): To => {
  const urlParams = new URLSearchParams();
  urlParams.set('teams', teamSlug);
  if (status) {
    urlParams.set('status', status);
  }
  if (DEFAULT_TIMEFRAME !== timeframe) {
    urlParams.set('t', timeframe);
  }
  return {pathname: ROUTE_PATHS.GOALS, search: urlParams.toString()};
};

export const createMemberGoalsPath = (
  memberLdap: string,
  status: string,
  timeframe: string,
  employeeLdap: string
): To => {
  const urlParams = new URLSearchParams();
  if (memberLdap !== employeeLdap) {
    urlParams.set('people', memberLdap);
  }
  urlParams.set('status', status);
  if (DEFAULT_TIMEFRAME !== timeframe) {
    urlParams.set('t', timeframe);
  }
  return {pathname: ROUTE_PATHS.GOALS, search: urlParams.toString()};
};

import {Button, IconButton} from '@dropbox/dig-components/dist/buttons';
import {LabelGroup} from '@dropbox/dig-components/dist/combinations';
import {Menu} from '@dropbox/dig-components/dist/menu';
import {Text} from '@dropbox/dig-components/typography';
import {Box, useTheme} from '@dropbox/dig-foundations';
import {UIIcon} from '@dropbox/dig-icons';
import {
  MoreHorizontalLine,
  PersonMultipleLine,
  TargetLine,
  Twinkle2Line,
} from '@dropbox/dig-icons/assets';
import {loggedInEmployeeAtom} from 'atoms/employee';
import {isMobileAtom} from 'atoms/layout';
import {Employee} from 'client';
import {Avatar} from 'components/DSYS/Avatar';
import {Link} from 'components/DSYS/Link';
import {StatusButtonIcon} from 'components/DSYS/StatusButtonIcon';
import {TeamMember, TeamsTreeItem} from 'components/teams/hooks';
import {STEAM_TEAM_SLUG} from 'constant';
import {sortEmployees} from 'helpers/utils';
import {DashboardsGoalCounts} from 'hooks/useDashboardsGoalCounts';
import {useEmployees} from 'hooks/useEmployee';
import {t} from 'i18next';
import {useAtomValue} from 'jotai';
import {useState} from 'react';
import {Trans} from 'react-i18next';
import {To, useNavigate} from 'react-router-dom';
import {
  DASHBOARD_STATUSES,
  DASHBOARDS_CELL_STATUS_COLORS,
  DASHBOARDS_DROPBOX_TEAM_SLUG,
} from 'views/dashboards/constants';
import {DashboardsChartContainer} from 'views/dashboards/DashboardsChartContainer';
import {DashboardsGoalsOverviewGraph} from 'views/dashboards/DashboardsGoalsOverviewGraph';
import {
  createMemberGoalsPath,
  createTeamDashboardsPath,
  createTeamGoalsPath,
  createTeamsPath,
  getStatusColorOverride,
  getStatusGoalCountsData,
  GoalCountsData,
} from 'views/dashboards/util';

import styles from './Dashboards.module.css';

interface HeatmapCellData {
  id: string;
  name: string;
  cellPath: To;
  viewGoalsPath: To;
  viewTeamPath?: To;
  teamSlug?: string;
  user?: Employee;
  shouldResizeToTwoCells?: boolean;
}

interface DashboardsHeatmapProps {
  heatmapStatusSelectorRef: React.RefObject<HTMLDivElement>;
  timeframe: string;
  teamName: string;
  selectedStatus: string;
  setSelectedStatus: (status: string) => void;
  subTeams: TeamsTreeItem[];
  teamSlug?: string;
  members?: TeamMember[];
  dashboardsGoalCounts?: DashboardsGoalCounts;
}

export const DashboardsHeatmap = (props: DashboardsHeatmapProps) => {
  const {subTeams, members} = props;

  if (subTeams.length) {
    return <DashboardsTeamsHeatmap {...props} />;
  }
  if (members?.length) {
    return <DashboardsTeamMembersHeatmap {...props} members={members} />;
  }
  return null;
};

export const DashboardsTeamsHeatmap = (
  props: DashboardsHeatmapProps & {
    selectedStatus: string;
    setSelectedStatus: (status: string) => void;
  }
) => {
  const {selectedStatus, subTeams, timeframe, dashboardsGoalCounts} = props;
  const sTeam = subTeams.find((subTeam) => subTeam.slug === STEAM_TEAM_SLUG);
  const sortedTeams = subTeams.sort((a, b) => a.teamName.localeCompare(b.teamName));

  // Order S-team first and resize it to 2 cells
  const filteredTeams = sTeam
    ? [sTeam, ...sortedTeams.filter((team) => team.slug !== STEAM_TEAM_SLUG)]
    : sortedTeams;
  const cells = filteredTeams.map((team) => ({
    id: team.slug,
    name: team.teamName,
    cellPath: createTeamDashboardsPath(team.slug, timeframe),
    viewGoalsPath: createTeamGoalsPath(team.slug, selectedStatus, timeframe),
    viewTeamPath: createTeamsPath(team.slug),
    teamSlug: team.slug,
    shouldResizeToTwoCells: team.slug === STEAM_TEAM_SLUG,
  }));
  const numCells = sTeam ? filteredTeams.length + 1 : filteredTeams.length;

  return (
    <DashboardsHeatmapComponent
      {...props}
      cells={cells}
      numCells={numCells}
      goalCounts={dashboardsGoalCounts?.subteams_counts}
      totalGoalCounts={dashboardsGoalCounts?.total_counts}
    />
  );
};

export const DashboardsTeamMembersHeatmap = ({
  heatmapStatusSelectorRef,
  timeframe,
  teamName,
  teamSlug,
  members,
  dashboardsGoalCounts,
  selectedStatus,
  setSelectedStatus,
}: DashboardsHeatmapProps & {
  selectedStatus: string;
  setSelectedStatus: (status: string) => void;
  members: TeamMember[];
}) => {
  const {employee} = useAtomValue(loggedInEmployeeAtom);
  const sortedTeamMembers = useEmployees({
    ldaps: members.map((member) => member.ldap) ?? [],
  }).sort(sortEmployees);

  const cells = sortedTeamMembers.map((member) => {
    const memberGoalsPath = createMemberGoalsPath(
      member.ldap,
      selectedStatus,
      timeframe,
      employee.ldap
    );
    return {
      id: member.ldap,
      name: member.name,
      cellPath: memberGoalsPath,
      viewGoalsPath: memberGoalsPath,
      viewTeamPath: teamSlug ? createTeamsPath(teamSlug) : undefined,
      teamSlug: teamSlug,
      user: member,
    };
  });

  return (
    <DashboardsHeatmapComponent
      heatmapStatusSelectorRef={heatmapStatusSelectorRef}
      timeframe={timeframe}
      teamName={teamName}
      selectedStatus={selectedStatus}
      setSelectedStatus={setSelectedStatus}
      cells={cells}
      numCells={cells.length}
      teamSlug={teamSlug}
      goalCounts={dashboardsGoalCounts?.members_counts}
      totalGoalCounts={dashboardsGoalCounts?.total_counts}
    />
  );
};

export const DashboardsHeatmapComponent = ({
  heatmapStatusSelectorRef,
  timeframe,
  teamName,
  selectedStatus,
  setSelectedStatus,
  cells,
  numCells,
  teamSlug,
  goalCounts,
  totalGoalCounts,
}: {
  heatmapStatusSelectorRef: React.RefObject<HTMLDivElement>;
  timeframe: string;
  teamName: string;
  selectedStatus: string;
  setSelectedStatus: (status: string) => void;
  cells: HeatmapCellData[];
  numCells: number;
  teamSlug?: string;
  goalCounts?: {[cellId: string]: {[status: string]: number}};
  totalGoalCounts?: {[status: string]: number};
}) => {
  const isMobile = useAtomValue(isMobileAtom);

  const numCols = window.innerWidth >= 1321 ? 4 : window.innerWidth >= 681 ? 2 : 1;
  const numRows = Math.ceil(numCells / numCols);
  const maxNumCells = numCols * numRows;
  const shouldRenderPlaceholderCells = numCells < maxNumCells && numCols > 1;

  const totalGoalCountsData = getStatusGoalCountsData(selectedStatus, totalGoalCounts);

  return (
    <DashboardsChartContainer
      title={t('team_heatmap')}
      subtitle={t('team_heatmap_subtitle')}
      icon={TargetLine}
      style={{gridTemplateRows: `repeat(${numRows}, 1fr)`}}
    >
      <Box paddingBottom="16" marginBottom="16" borderColor="Border Subtle" borderBottom="Solid" />
      <Box
        display="flex"
        alignItems={isMobile ? 'flex-start' : 'center'}
        flexDirection={isMobile ? 'column' : 'row'}
        style={{gap: '16px'}}
      >
        <DashboardsHeatmapStatusMenu
          heatmapStatusSelectorRef={heatmapStatusSelectorRef}
          selectedStatus={selectedStatus}
          setSelectedStatus={setSelectedStatus}
        />
        <Box display="flex" alignItems="center">
          {totalGoalCountsData && (
            <>
              <Box display="flex" alignItems="center" style={{gap: '3px'}}>
                <UIIcon src={Twinkle2Line} size="small" color="var(--dig-color__text__subtle)" />
                <Text size="small">
                  <Trans
                    i18nKey="team_heatmap_info_message"
                    t={t}
                    values={{
                      perc: totalGoalCountsData.percentageCeil,
                      team: teamName,
                      status: t(selectedStatus).toLowerCase(),
                      count: totalGoalCountsData.statusCount,
                      total: totalGoalCountsData.totalCount,
                    }}
                    components={{
                      Text: <Text size="small" isBold />,
                    }}
                  />
                </Text>
              </Box>
              {teamSlug && teamSlug !== DASHBOARDS_DROPBOX_TEAM_SLUG && (
                <Box marginLeft="6">
                  <Link to={createTeamGoalsPath(teamSlug, selectedStatus, timeframe)}>
                    <Text size="small" isBold>
                      {t('view_all')}
                    </Text>
                  </Link>
                </Box>
              )}
            </>
          )}
        </Box>
      </Box>
      <div className={styles.dashboardsHeatmapList}>
        {cells.map((cellData) => {
          const goalCountsData = getStatusGoalCountsData(selectedStatus, goalCounts?.[cellData.id]);
          return (
            <DashboardsHeatmapCell
              key={`dashboards-heatmap-cell__${cellData.id}`}
              timeframe={timeframe}
              cellData={cellData}
              numCols={numCols}
              numRows={numRows}
              goalCountsData={goalCountsData}
            />
          );
        })}
        {shouldRenderPlaceholderCells &&
          [...Array(Math.floor(maxNumCells - numCells))].map((_, index) => (
            <DashboardsHeatmapPlaceholderCell
              key={`dashboards-heatmap-placeholder-cell__${index}`}
            />
          ))}
      </div>
    </DashboardsChartContainer>
  );
};

export const DashboardsHeatmapCell = ({
  timeframe,
  cellData,
  numCols,
  numRows,
  goalCountsData,
}: {
  timeframe: string;
  cellData: HeatmapCellData;
  numCols: number;
  numRows: number;
  goalCountsData?: GoalCountsData;
}) => {
  const {name, cellPath, user, shouldResizeToTwoCells} = cellData;
  const navigate = useNavigate();
  const {mode} = useTheme();

  const cellStatusColor = goalCountsData?.cellStatusColor;
  const textColor =
    mode === 'dark' && cellStatusColor ? 'var(--dig-color__selection__on-base)' : undefined;
  const minHeight = numRows > 2 ? '112px' : '296px';
  const onClick = cellPath ? () => navigate(cellPath) : undefined;

  const [isHovered, setHovered] = useState(false);

  const cssClasses = [styles.dashboardsHeatmapCell];
  if (numCols > 1 && shouldResizeToTwoCells) {
    cssClasses.push(styles.dashboardsHeatmapTwoCells);
  }

  return (
    <Box
      // Resize S-team to take up 2 cells
      className={cssClasses.join(' ')}
      style={{
        backgroundColor: isHovered ? undefined : cellStatusColor,
        minHeight,
        maxHeight: '296px',
      }}
      borderRadius="Medium"
      borderColor="Border Subtle"
      borderStyle="Solid"
      borderWidth="1"
      onClick={onClick}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <Box display="flex" alignItems="flex-start" justifyContent="space-between">
        <LabelGroup
          withLeftAccessory={!!user && <Avatar user={user} size="small" />}
          withText={
            <Text size="small" style={{color: textColor}}>
              {name}
            </Text>
          }
        />
        {isHovered && <DashboardsHeatmapCellActionMenu cellData={cellData} />}
      </Box>
      {goalCountsData && (
        <Box display="flex" flexDirection="column">
          <Box style={{alignSelf: 'flex-end', marginLeft: 'auto'}}>
            {goalCountsData.totalCount ? (
              <Text size="large" style={{color: textColor}} isBold>
                {`${goalCountsData.percentageCeil}%`}
              </Text>
            ) : (
              <Text size="small" isBold>
                {t('no_goals')}
              </Text>
            )}
          </Box>
          {!!(goalCountsData.statusCounts && goalCountsData.totalCount) && (
            <Box
              className={`${styles.dashboardsHeatmapCellGraph} ${
                isHovered ? styles.dashboardsHeatmapCellGraphShiftUp : ''
              }`}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <DashboardsGoalsOverviewGraph
                timeframe={timeframe}
                height={12}
                width="160px"
                goalCounts={goalCountsData.statusCounts}
                showInfoOnHover
              />
              <Text size="small" color="subtle">
                {t(goalCountsData.status)}
              </Text>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

const DashboardsHeatmapCellActionMenu = ({cellData}: {cellData: HeatmapCellData}) => {
  const navigate = useNavigate();

  return (
    <Menu.Wrapper
      onClick={(e: React.SyntheticEvent) => {
        e.stopPropagation();
      }}
      onMouseLeave={(e: React.SyntheticEvent) => {
        e.stopPropagation();
      }}
    >
      {({getContentProps, getTriggerProps}) => (
        <>
          <IconButton {...getTriggerProps()} variant="borderless" shape="circular" size="small">
            <UIIcon src={MoreHorizontalLine} />
          </IconButton>
          <Menu.Content {...getContentProps()} minWidth="214px">
            <Menu.Segment>
              <Menu.ActionItem
                key="view_goals"
                value="view_goals"
                withLeftAccessory={
                  <UIIcon src={TargetLine} color="var(--dig-color__text__subtle)" />
                }
                onClick={() => navigate(cellData.viewGoalsPath)}
              >
                {t('view_goals')}
              </Menu.ActionItem>
              {cellData.viewTeamPath && (
                <Menu.ActionItem
                  key="view_team"
                  value="view_team"
                  withLeftAccessory={
                    <UIIcon src={PersonMultipleLine} color="var(--dig-color__text__subtle)" />
                  }
                  onClick={() => navigate(cellData.viewTeamPath!)}
                >
                  {t('view_team')}
                </Menu.ActionItem>
              )}
            </Menu.Segment>
          </Menu.Content>
        </>
      )}
    </Menu.Wrapper>
  );
};

export const DashboardsHeatmapPlaceholderCell = () => (
  <Box
    style={{
      opacity: 0.4,
      background: 'var(--Border-Subtle, rgba(0, 0, 0, 0.14))',
      minHeight: '112px',
      maxHeight: '296px',
    }}
    borderRadius="Medium"
  />
);

export const DashboardsHeatmapStatusMenu = ({
  heatmapStatusSelectorRef,
  selectedStatus,
  setSelectedStatus,
}: {
  heatmapStatusSelectorRef: React.RefObject<HTMLDivElement>;
  selectedStatus: string;
  setSelectedStatus: (status: string) => void;
}) => {
  return (
    <Menu.Wrapper onSelection={setSelectedStatus}>
      {({getContentProps, getTriggerProps}) => (
        <>
          <Button
            {...getTriggerProps()}
            variant="outline"
            size="large"
            fullWidth
            withDropdownIcon
            withIconStart={
              <StatusButtonIcon
                status={selectedStatus}
                statusColorOverride={getStatusColorOverride(
                  selectedStatus,
                  DASHBOARDS_CELL_STATUS_COLORS
                )}
              />
            }
          >
            <Box as={Text} textAlign="left" style={{width: '230px'}} ref={heatmapStatusSelectorRef}>
              {t(selectedStatus)}
            </Box>
          </Button>
          <Menu.Content {...getContentProps()} style={{width: '290px'}}>
            <Menu.Segment>
              {DASHBOARD_STATUSES.map((status) => (
                <Menu.SelectItem
                  key={`dashboards-heatmap-status-option__${status}`}
                  value={status}
                  selected={selectedStatus === status}
                  withLeftAccessory={
                    <StatusButtonIcon
                      status={status}
                      statusColorOverride={getStatusColorOverride(
                        status,
                        DASHBOARDS_CELL_STATUS_COLORS
                      )}
                    />
                  }
                >
                  {t(status)}
                </Menu.SelectItem>
              ))}
            </Menu.Segment>
          </Menu.Content>
        </>
      )}
    </Menu.Wrapper>
  );
};
